@import "https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;700&display=swap";
html {
  box-sizing: border-box;
}

*, :before, :after {
  box-sizing: inherit;
}

html {
  height: 100vh;
}

body {
  box-sizing: border-box;
  background: #e9e9e9;
  height: calc(100vh - 1rem);
  margin: 0;
  padding: .5rem;
}

body ::-moz-placeholder {
  font-family: Roboto Mono, Calibri;
}

body, body ::placeholder {
  font-family: Roboto Mono, Calibri;
}

#editorjs {
  background-color: #fff;
  min-height: calc(100% - 3rem);
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.codex-editor__redactor {
  padding-bottom: 0 !important;
}

textarea {
  border: none;
  width: 100%;
  font-size: 11pt;
}

.list-element {
  padding: .25rem;
}

.tag, .date {
  font-weight: bold;
}

.date {
  text-decoration: underline;
}

.ce-block {
  animation: none;
}

[data-item-name="paragraph"] {
  display: none;
}

div.ce-block__content, div.ce-toolbar__content {
  max-width: calc(100% - 1rem);
  margin-left: .5rem;
}

div[contenteditable] {
  white-space: pre-wrap;
  overflow-wrap: break-word;
  outline: none;
}

.tags-picker {
  z-index: 999;
  background-color: #d3d3d3;
  padding: 0;
  list-style-type: none;
  position: fixed;
}

.tags-picker li.active {
  color: #fff;
  cursor: pointer;
  background-color: #1e90ff;
}

.tag-completed {
  text-decoration: line-through;
}

.ce-toolbar {
  display: none !important;
}

.ball-gif {
  width: 18px;
  margin-left: .5rem;
}

#filter-input {
  box-sizing: border-box;
  width: 100%;
  height: 2rem;
  padding-left: .5rem;
  display: block;
}

/*# sourceMappingURL=index.b72f33d4.css.map */
